import '../App.css';

import Faq from "react-faq-component";
import React from 'react';
import './FAQ.css';
import $ from "jquery";
import {Rubrik} from "./ContentStyles";
import SpinnerX from './SpinnerX';


const styles = {
    arrowColor: "#ffaec9",
    rowContentColor: '#333',
    titleTextColor: '#f00',
    bgColor: '#eee',
    titleTextSize: '48px'
  };
  
  const config = {
     animate: true,
    // arrowIcon: "V",
    // tabFocus: true
  };

  export default class FaqX extends React.Component  {

    constructor(props) {
      super(props);
      this.state = {
        data : null
      };
    }


    componentDidMount() {
      this.populateFaq();   
      this.setState({});
    }

    populateFaq= () => {
      var url = "/backend/Faqs?FilterGroup=Q";
      var faqData = {title: "", rows: []};
    
      const that = this;
      fetch(url)
      .then((response) => response.json())
      .then((findresponse)=>{
        $.each(findresponse, function(id,obj) {
          var toAdd = {title: obj.Title, content: obj.Answer };
          faqData.rows.push(toAdd);
        });

        that.state.data = faqData;
        this.setState({});
      })        
    }

    render(){
      if (this.state.data == null) {
        return (          <>
          <Rubrik>Frequently Asked Questions!</Rubrik>
          <SpinnerX /><br></br>
          </>
        );
      }
      else {
        return(
          <>
            <Rubrik>Frågor och svar!</Rubrik>
            <div>
              <Faq data={this.state.data} styles={styles} config={config} />
            </div>
          </>
        );
      };
    }
}
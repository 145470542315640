import '../App.css';

import { Container} from 'react-bootstrap';

import Faq from "./Faq";
import React, { useState, Component } from 'react';
import {Sida,Rubrik,Stycke,ContentWrapper} from "./ContentStyles";

const HowToStartup = () => {

  return(
    <ContentWrapper>

      <Container>
      <Sida>

      <Rubrik>Hur fungerar digital kö?</Rubrik>
        <Stycke>
          <ul>
            <li style={{paddingBottom: 16}}>Dina kunder kan välja 3 olika sätt att ställa sig i kö:<br /><ol><li>Scanna QR med sin telefon.</li><li>Ställa sig i kö via vår app.</li><li>Be personalen om ett könummer (under 2% väljer detta).</li></ol><small>I alternativ 1 och 2 följer kunden kön via sin telefon, i alternativ 3 ropar personalen upp numret.</small></li>
            <li style={{paddingBottom: 16}}>I butiken använder du vår app för att hantera kön (tex klicka fram nästa nummer, se hur många som står i kö.).</li>
          </ul>
        </Stycke>
        
        <Rubrik>Hur kommer jag igång?</Rubrik>
        <Stycke>
          <ol>
            <li style={{paddingBottom: 16}}>Ladda ner QmeNow till en internetansluten telefon eller surfplatta<br></br>
              <a href="https://apps.apple.com/us/app/qmenow/id1567014927" target="_blank"><img height='40px' src="https://www.qmenow.com/images/AppStore.png" /></a>&nbsp;
              <a href="https://play.google.com/store/apps/details?id=cloudsolution.qmenow" target="_blank"><img height='40px' src="https://www.qmenow.com/images/GooglePlay.png" /></a>&nbsp;</li>
            <li style={{paddingBottom: 16}}>Därefter behöver du registrera dig som kund, du kan registrera dig via vårt <a href="/signup">Registreringsformulär</a> alternativt efter via appen.<br></br><small>Vi appen klicka på Butiksinloggning + Bli Kund för att komma till registreringen.</small></li>
            <li style={{paddingBottom: 16}}>Efter att du registrerat dig kommer du få ett bekräftelsemejl med en länk för att bekräfta din mailadress.<br></br><small>Om du inte får något bekräftelsemejl kontrollera din mapp för skräppost.</small></li>
            <li style={{paddingBottom: 16}}>Efter att du gjort ovanstående steg kan du logga in via appen (Butiksinloggning) och skapa och namnge din första kö.</li>
            <li style={{paddingBottom: 16}}>När du skapat ditt konto och första kö behöver du skriva ut instruktioner till dina kunder. Instruktionerna finns tillgängliga som PDF (under Min Profil) efter att du loggat in via <a href="/login">Kundinloggning</a></li>
          </ol>
        </Stycke>

        <Rubrik>Det är fortfarande lite oklart?</Rubrik>
        <Stycke>
        Tveka inte att <a href="/contact">Kontakta oss</a>
        </Stycke>        

            
        <Faq />

      </Sida>    
      </Container>

    </ContentWrapper>
  );
}

export default HowToStartup;
import '../App.css';

import { Container} from 'react-bootstrap';

import Faq from "./Faq";
import React, { useState, Component } from 'react';
import {Sida,Rubrik,Stycke,ContentWrapper} from "./ContentStyles";
import Prices from "./Prices";

const Home = () => {

   return(
        <ContentWrapper>
    
        <Container>
        <Sida>
          
          <Rubrik>Ge dina kunder en digital kölapp :)</Rubrik>
          <div style={{float:"left", marginRight:"20px"}}><img src="/images/lapp01.png"></img></div>
          <div>
            <Stycke>
              Tillsammans gör vi det smidigt, klimatneutralt och smittsäkert!. Dina kunder får en digital kölapp genom att scanna en QR-kod med sin mobil eller genom att ladda ner vår app. Efter att kunden fått sin kölapp håller den koll på kön via sin telefon.
              Digital kö har många fördelar, tex att kunna ställa sig i kö innan man åker hemifrån eller vid lång kö kan kunden göra annat under tiden som den köar.
              <br /><br />
              Hur kommer jag igång med digital kö? <a href="/HowToStartup">Kolla in vår guide!</a>
              </Stycke>

              
          </div>
    
          <div style={{clear:"both", paddingTop:"20px"}}>
          <Stycke>
              <a   href="https://apps.apple.com/us/app/qmenow/id1567014927"><img height='40px' src="/images/AppStore.png" /></a>&nbsp;
              <a   href="https://play.google.com/store/apps/details?id=cloudsolution.qmenow"><img height='40px' src="/images/GooglePlay.png" /></a>&nbsp;
          </Stycke>
          </div>

          <Rubrik>Abonnemang</Rubrik>
          <Prices />
          <Faq />

        </Sida>    
        </Container>

        </ContentWrapper>
      );
   }

export default Home;
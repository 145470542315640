import '../App.css';
import {PricingTable, PricingSlot, PricingDetail} from 'react-pricing-table';
import React, { useState, Component } from 'react';
import {  useNavigate } from 'react-router';

const Prices = () => {
    let navigate = useNavigate();

    function handleSubmitProd1(e) {
        e.preventDefault();
        navigate('/signup?defaultproduct=Q1'); 
    }    

    function handleSubmitProd2(e) {
        e.preventDefault();
        navigate('/signup?defaultproduct=Q2'); 
    }    
    
    function handleSubmitProd3(e) {
        e.preventDefault();
        navigate('/signup?defaultproduct=Q3'); 
    }    
    
    function handleSubmitProd4(e) {
        e.preventDefault();
        navigate('/signup?defaultproduct=Q4'); 
    }        

    return (
    <>
        <PricingTable highlightColor='#ffaec9'>

            <PricingSlot onClick={handleSubmitProd1}   buttonText='SIGN UP' title='BASIC/Månad' priceText='695:-/månad' >
                <PricingDetail> <b>Bindningstid</b> 0 månader</PricingDetail>
                <PricingDetail> <b>Startkostnad</b> 995:-</PricingDetail>                

                <PricingDetail> <b>Antal köer</b> 1</PricingDetail>
                <PricingDetail> <b>App</b> Ja</PricingDetail>   
                <PricingDetail> <b>Matmeny</b> Nej</PricingDetail>  
                <PricingDetail> <b>TV</b> Nej</PricingDetail>            
                <PricingDetail> <b>Support</b> Mail</PricingDetail>      
            </PricingSlot>

      

            <PricingSlot onClick={handleSubmitProd3}   buttonText='SIGN UP' title='PRO/Månad' priceText='1295:-/månad'>
                <PricingDetail> <b>Bindningstid</b> 0 månader</PricingDetail>
                <PricingDetail> <b>Startkostnad</b> 995:-</PricingDetail>    

                <PricingDetail> <b>Antal köer</b> 3</PricingDetail>
                <PricingDetail> <b>App</b> Ja</PricingDetail>
                <PricingDetail> <b>Matmeny</b> Ja</PricingDetail> 
                <PricingDetail> <b>TV</b> Ja</PricingDetail>
                <PricingDetail> <b>Support</b> Mail</PricingDetail>      
            </PricingSlot>
            
        </PricingTable>
    </>
  )
};

export default Prices;        
import React, { useState, useEffect } from "react";
import "./Login.css";

import { Container, Button ,Form, Alert} from 'react-bootstrap';
import SpinnerX from '../SpinnerX';

import {Sida,Rubrik,Stycke,ContentWrapper,Artickel} from "../ContentStyles";

const Info = () => {
  const [PasswordResetGUID, setPasswordResetGUID] = useState(window.location.pathname.replace('/password/request/set/',''));

  const [resultText, setResultText] = useState(":)");
  const [resultVariant, setResultVariant] = useState("success");
  const [resultVisible, setResultVisible] = useState(false);
  const [spinnerHidden, setSpinnerHidden] = useState(true);    

  const [size, setSize] = useState({
    x: window.innerWidth,
    y: (window.innerHeight-500)
  });
  
  const updateSize = () => {
    setSize({
      x: window.innerWidth,
      y: (window.innerHeight-500)
    });
  }
  useEffect(() => (window.onresize = updateSize), []);  
  useEffect(() => (window.onscroll = updateSize), []);  

  function handleSubmit(e) {
    e.preventDefault();

    setSpinnerHidden(false);
    setResultVisible(true);

    const formData = new FormData(e.target);
    formData.append('PasswordResetGUID',PasswordResetGUID);
    const formDataObj = Object.fromEntries(formData.entries());

    var PostData = {SystemID:"Q", FormData : formDataObj};
    
    fetch('/backend/auth/password/request/set', {
        method: 'POST',
        headers: {
           'Content-Type': 'application/json'
        },

        body: JSON.stringify(PostData),
     }).then(response => response.json())
     .then(data => {
        if (data.errorsException===false) {
            setResultVariant("success");      
            setResultText(data.resultText);        
            setSpinnerHidden(true);
            setResultVisible(true);
        } else {
            setResultVariant("warning");
            setResultText(data.resultText);
            setSpinnerHidden(true);
            setResultVisible(true);
        }
     })
     .catch(error => {
        setResultVariant("warning");
        setResultText("Ett fel uppstod!");
        setSpinnerHidden(true);
        setResultVisible(true);
      });
    }  
    
  return (
    <ContentWrapper>
    <Container>
    <Sida style={{minHeight:size.y}}>
    <Artickel>
        <Rubrik>Ange nytt lösenord</Rubrik>
        <Form onSubmit={handleSubmit}>

              <Form.Group className="mb-3">
                  <Form.Label className="FormHeaderText">Password</Form.Label>
                  <Form.Control type="password" name="password" />
              </Form.Group>  

              <Form.Group className="mb-3">
                  <Form.Label className="FormHeaderText">Password</Form.Label>
                  <Form.Control type="password" name="password_validate" />
              </Form.Group>                      

              <Form.Group className="mb-3"  hidden={spinnerHidden}>
                  <SpinnerX  />
              </Form.Group>                                      
                  
              <Form.Group className="mb-3"  hidden={!resultVisible}>
                  <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
              </Form.Group>        

              <Form.Group className="mb-3">
                  <Button className="QmeButton" variant="primary" type="submit">Skicka</Button>
              </Form.Group>   

        </Form> 

    </Artickel>
    </Sida>
    </Container>
    </ContentWrapper>
  );
};

export default Info;